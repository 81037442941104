<template>
  <b-container class="m-0 p-0">
    <b-row class="mb-2" v-for="(dose, index) in doses" :key="index">
      <b-col cols="4">
        <h4 class="subtitle-form">{{ dose.display }}</h4>
      </b-col>
      <b-col cols="8" class="d-flex align-items-start">
        <!-- Dose -->        
        <NumericInput
                inputRef="input"
                :value="hasComputedDoses ? (dose.name == 'min' ? computedDoses.minDose : computedDoses.maxDose) : currentEntry[dose.key] || currentEditedEntry[dose.key] || currentEntry[dose.key]"
                @input="updateDose(dose.name, $event)"
                :numberOfDigits="1"
                class="form-control-fx mr-2"
                :required="true"
        />
        <!-- Unité -->
        <b-select
          size="sm"
          :options="inputFormByPtype.doseUnits"
          text-field="unit"
          value-field="id"
          :value="currentEditedEntry.doseUnit || currentEntry.doseUnit || getSelectedDoseUnit"
          @input="updateUnit"
          required
        ></b-select>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";

import { mapActions, mapGetters } from "vuex";
import NumericInput from "@/components/commons/NumericInput.vue";

export default {
  mixins: [DetailUpdateMixin],
  data() {
    return {
      doses: [
        {
          name: "min",
          display: "Dose min",
          key: "minDoseValue",
        },
        {
          name: "max",
          display: "Dose max",
          key: "maxDoseValue",
        },
      ],
      doseUnit: null,
      computedDoses: {},
      products: [],
      percents: [],
      mixedInput_composition: [],
      name: "",
      hasComputedDoses: false,
      hascomputedName: false
    };
  },
  async mounted() {
    this.init();
  },

  methods: {
    /**
     * Enregistre la valeur de la dose dans currentEditedEntry
     */
    updateDose(name, value) {
      const payload = {};
      const dose = this.doses.find(d => d.name == name);
      payload[dose.key] = parseFloat(value);
      this.$store.dispatch(this.storeEditAction, payload);
    },

    /**
     * Enregistre l'id l'unité choisie dans currentEditedEntry
     */
    updateUnit(value) {
      this.$store.dispatch(this.storeEditAction, {
        doseUnit: value,
      });
    },

    async init() {
      if (this.$route.name == "inputStock.edit") {
        await this.$store.dispatch(this.storeEditAction, {
            minDoseValue: this.currentEntry.minDoseValue,
            maxDoseValue: this.currentEntry.maxDoseValue,
            name: this.currentEntry.name
        });
      }
      if (this.$route.name.includes("edit") && !this.currentEntry.doseUnit && this.inputFormByPtype.doseUnits && this.inputFormByPtype.doseUnits.length > 0) {
        this.updateUnit(this.inputFormByPtype.doseUnits[0].id);
      }
    },

    isValue(dose) {
        if (this.hasComputedDoses) {
            return dose.name === 'min' ? this.computedDoses.minDose : this.computedDoses.maxDose;
        } else {
            const doseKey = dose.key;
            return this.currentEntry[doseKey] || this.currentEditedEntry[doseKey] || this.currentEntry[doseKey];
        }
    },
  },
  computed: {
    ...mapGetters(["inputFormByPtype"]),

    /**
     * Retourne l'unité de dose présélectionnée
     */
    getSelectedDoseUnit() {
      if(this.inputFormByPtype.doseUnits && this.inputFormByPtype.doseUnits.length > 0)
        return this.inputFormByPtype.doseUnits[0].id;
    }
  },

  watch: {
    currentEditedEntry: {
      deep: true,
      handler: function() {
        if(this.mixedInput_composition !== this.currentEditedEntry.mixedInput_composition) {
          this.mixedInput_composition = this.currentEditedEntry.mixedInput_composition;
        }
      }
    },

    mixedInput_composition: {
      deep: true,
      handler: async function() {
        this.products = [];
        this.percents = [];
        if(this.mixedInput_composition) {
          this.mixedInput_composition.map(input => {
            this.products.push(input.product);
            this.percents.push(input.percentInComposition ? input.percentInComposition : 0);
          });
          this.computedDoses = await this.$store.dispatch("inputStock/getMixedDoses", {
            products: this.products, percents: this.percents
          });
          this.name = await this.$store.dispatch("inputStock/getMixedName", {
            products: this.products, percents: this.percents
          });
          // Le nom des produits ne doit pas dépasser 50 caractères
          this.name = this.name.slice(0, 50);
          if (this.$route.name != "inputStock.edit")
          {
            this.hasComputedDoses = true;
            this.hascomputedName = true;
            await this.$store.dispatch(this.storeEditAction, {
              minDoseValue: this.computedDoses.minDose,
              maxDoseValue: this.computedDoses.maxDose,
              name: this.name
            });
          }
          else {
            if(this.currentEntry.minDoseValue == this.computedDoses.minDose
            && this.currentEntry.maxDoseValue == this.computedDoses.maxDose) {
              this.hasComputedDoses = true;
            }
            if(this.currentEntry.name == this.name) {
              this.hascomputedName = true;
            }
            if(this.hasComputedDoses == true) {
              await this.$store.dispatch(this.storeEditAction, {
                minDoseValue: this.computedDoses.minDose,
                maxDoseValue: this.computedDoses.maxDose,
              });
            }
            if(this.hascomputedName == true) {
              await this.$store.dispatch(this.storeEditAction, {
                name: this.name
              });
            }
          }
        }
      }
    }
  },
  components: {
    NumericInput
  }
};
</script>

<style lang="scss" scoped></style>
